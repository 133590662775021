import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Dropdown, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import logo from "../assets/images/logo.svg";
import logo_dark from "../assets/images/logo_dark.svg";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
// import api
import { logout, UpdateLanguage } from "../api/users";
import ThemeToggle from "./ThemeToggle";
import { useTheme } from "./ThemeContext";

//import lib
import { toFixedDown, truncateDecimals } from "../lib/roundOf";
import isEmpty from "../lib/isEmpty";
import { toastAlert } from "../lib/toastAlert";

//import modal
import ProfileUpdateModal from "./AccountSettings/ProfileModal";
import {
  getArbiProfit,
  getAssetData,
  setAvailableBal,
} from "../api/walletAction";
import { getStackingEstimate } from "../api/staking";
import { currencyChange } from "../lib/pairHelper";
import { getLang, setLang } from "../lib/localStorage";
import { getAllOpenPosition } from "../api/tradeAction";
import { unrealizedPnL } from "../lib/bybit";
import SocketContext from "../context/SocketContext";

const NavbarInner = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // redux-state
  const { notifiyCount } = useSelector((state) => state.notice);
  const currency = useSelector((state) => state.currency);
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector((state) => state.priceConversion);
  const { isStake, defaultcurrency, isHedge, isTrade, isLiveEvents, langCode } =
    useSelector((state) => state.account);
  const availableBal = useSelector((state) => state.availableBal);
  const siteDoc = useSelector((state) => state.siteSetting);
  const pairListData = useSelector((state) => state.pairList);
  const marketPrice = useSelector((state) => state.marketPrice);

  //state
  const [available, setTotalAvailable] = useState(0);
  const [tradedata, setTradeData] = useState();
  const [ProfileModal, setProfileModal] = useState(false);
  const [arbitragePnl, setarbitragePnl] = useState(0);
  const [data, setData] = useState();
  const [totalPair, setTotalPair] = useState([]);
  const [totalPNL, setTotalPNL] = useState(0);
  const [equity, setEquity] = useState(0);
  const [usedMargin, setMargin] = useState(0);

  const { theme } = useTheme();
  const socketContext = useContext(SocketContext);

  //function
  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    $(document).ready(function () {
      $(".nav_after_login li a").click(function () {
        $("body").css({ overflow: "unset" });
      });
    });
  }

  const handleAsset = async () => {
    try {
      let totalAmount = 0;
      let lockedBal = 0;
      let tempArr = [...walletData];

      currency?.length >= 0 &&
        currency.map((item, index) => {
          let PriceCnv =
            priceConversion &&
            priceConversion.length > 0 &&
            priceConversion.find(
              (el) =>
                el.baseSymbol == item.currencySymbol &&
                el.convertSymbol == "USD"
            );
          let pairIndex =
            tempArr &&
            tempArr.findIndex((el) => {
              return el._id == item._id;
            });
          if (pairIndex >= 0 && !isEmpty(pairIndex)) {
            tempArr[pairIndex] = {
              ...tempArr[pairIndex],
              ...{
                lockedBal: !isEmpty(PriceCnv?.convertPrice)
                  ? parseFloat(tempArr[pairIndex].lockedBal) *
                    parseFloat(PriceCnv.convertPrice)
                  : parseFloat(tempArr[pairIndex].lockedBal),
                USDValue: !isEmpty(PriceCnv?.convertPrice)
                  ? parseFloat(
                      tempArr[pairIndex].stakeBal - tempArr[pairIndex].lockedBal
                    ) * parseFloat(PriceCnv?.convertPrice)
                  : parseFloat(
                      tempArr[pairIndex].stakeBal - tempArr[pairIndex].lockedBal
                    ),
              },
            };
            totalAmount += tempArr[pairIndex].USDValue;
            lockedBal += tempArr[pairIndex].lockedBal;
          }
        });
      if (defaultcurrency == "USD") {
        setTotalAvailable(totalAmount);
        setMargin(lockedBal);
        setarbitragePnl(availableBal?.profit);
      } else {
        let PriceChange =
          priceConversion &&
          priceConversion.length > 0 &&
          priceConversion.find(
            (el) =>
              el.baseSymbol == "USD" && el.convertSymbol == defaultcurrency
          );
        if (PriceChange?.convertPrice) {
          let totalbalance = !isEmpty(PriceChange?.convertPrice)
            ? parseFloat(totalAmount * PriceChange?.convertPrice)
            : 0;
          let totalPNlL = !isEmpty(PriceChange?.convertPrice)
            ? parseFloat(PriceChange?.convertPrice * availableBal?.profit)
            : 0;

          let totalUsedmargin = !isEmpty(PriceChange?.convertPrice)
            ? parseFloat(lockedBal * PriceChange?.convertPrice)
            : 0;
          setTotalAvailable(totalbalance);
          setMargin(totalUsedmargin);
          setarbitragePnl(totalPNlL);
        }
      }
    } catch (err) {
      console.log("err:------", err);
    }
  };

  const getStackinginfo = async (spotPairId, type) => {
    try {
      const { status, result } = await getStackingEstimate(spotPairId);
      if (status == "success") {
        // console.log(result, "---------- reulst")
        if (!isEmpty(result)) {
          if (defaultcurrency == "USD") {
            setTradeData(result?.actStackToTalAmou);
          } else {
            let PriceChange =
              priceConversion &&
              priceConversion.length > 0 &&
              priceConversion.find(
                (el) =>
                  el.baseSymbol == "USD" && el.convertSymbol == defaultcurrency
              );
            if (PriceChange?.convertPrice) {
              let totalbalance = !isEmpty(PriceChange?.convertPrice)
                ? parseFloat(
                    result?.actStackToTalAmou * PriceChange?.convertPrice
                  )
                : 0;
              setTradeData(totalbalance);
            }
          }
        }
      } else {
        setTradeData(0);
      }
    } catch (err) {
      console.log(err, "errro");
    }
  };

  const handleCloseModal = () => {
    setProfileModal(false);
  };

  useEffect(() => {
    if (
      !isEmpty(walletData) &&
      !isEmpty(currency) &&
      !isEmpty(priceConversion)
    ) {
      handleAsset();
      getStackinginfo();
    }
  }, [walletData, currency, priceConversion]);

  useEffect(() => {
    loadScript();
    getArbiProfit(dispatch);
    // getAssetData(dispatch)
  }, []);

  const handleTrade = () => {
    if (!isTrade) {
      toastAlert("error", t("TRADE_ACCESS"), "login");
    }
  };

  const handleStake = () => {
    if (!isStake) {
      toastAlert("error", t("STAKE_ACCESS"), "login");
    }
  };
  const handleLiveEvent = () => {
    if (!isLiveEvents) {
      toastAlert("error", t("LIVE_EVENT_ACCESS"), "login");
    }
  };

  const handlehedge = () => {
    if (!isHedge) {
      toastAlert("error", t("HEDGE_ACCESS"), "login");
    }
  };

  const langList = [
    {
      value: "en",
      image: require("../assets/images/EN.png"),
      label: "English",
    },
    { value: "ar", image: require("../assets/images/AR.png"), label: "Arabic" },
    { value: "fr", image: require("../assets/images/FR.png"), label: "French" },
    {
      value: "sp",
      image: require("../assets/images/SP.png"),
      label: "Spanish",
    },
  ];

  const [defaultLang, setDefaultLang] = useState({
    value: "en",
    image: require("../assets/images/EN.png"),
    label: "English",
  });

  const [language, setLanguage] = useState("en");

  const handleLanguage = (lang, option) => {
    setLang(lang);
    setLanguage(lang);
    setDefaultLang(option);
    i18n.changeLanguage(lang);
  };

  const fetchTradeHistory = async () => {
    const { status, result } = await getAllOpenPosition({});
    if (status == "success") {
      setData(result);
    }
  };

  useEffect(() => {
    let langCode = getLang();

    if (isEmpty(langCode)) {
      setLang(language);
      i18n.changeLanguage(language);
    } else {
      setLang(langCode);
      i18n.changeLanguage(langCode);
      const selectedLang = langList.find((item) => item.value === langCode);
      if (selectedLang) setDefaultLang(selectedLang);
      setLanguage(langCode);
    }
  }, [language]);

  useEffect(() => {
    fetchTradeHistory();
  }, []);

  useEffect(() => {
    let allPairPriceUpdate = [...pairListData];
    const socket = socketContext.socket;

    socket.on("marketPrice", (result) => {
      // console.log(result, "--------result");
      if (result) {
        let pairIndex = allPairPriceUpdate.findIndex(
          (el) => el._id.toString() === result.pairId.toString()
        );
        if (pairIndex >= 0) {
          allPairPriceUpdate[pairIndex] = {
            ...allPairPriceUpdate[pairIndex],
            ...{
              bidPrice: result?.data?.bidPrice,
              askPrice: result?.data?.askPrice,
              markPrice: result?.data?.markPrice,
              change: result?.data?.change,
            },
          };
          setTotalPair(allPairPriceUpdate);
        }
      }
    });

    // return () => {
    //   socket.off("marketPrice");
    // };
  }, [pairListData]);

  useEffect(() => {
    if (data && data.length > 0 && !isEmpty(totalPair)) {
      // console.log(data,'----787', totalPair)
      let totalPnl = 0;
      data.forEach((item) => {
        let filter =
          totalPair &&
          totalPair.find((el) => el._id.toString() === item.pairId.toString());
        let price = item.side === "sell" ? filter?.askPrice : filter?.bidPrice;
        let pnl = unrealizedPnL({
          entryPrice: item.entryPrice,
          quantity: item.quantity,
          lastPrice: price,
          buyorsell: item.side,
        });

        let totalSpread =
          parseFloat(item?.userId?.userSpread || 0) +
          parseFloat(filter?.spread || 0);
        totalPnl += pnl + totalSpread * item.quantity;
      });
      setTotalPNL(truncateDecimals(totalPnl, 2));
    }
  }, [totalPair, data, marketPrice]);

  useEffect(() => {
    const updatedEquity = parseFloat(available) + parseFloat(totalPNL);
    setEquity(updatedEquity);
  }, [available, totalPNL]);

  return (
    <>
      <div className="container-fluid">
        <ProfileUpdateModal isShow={ProfileModal} onHide={handleCloseModal} />

        <nav className="navbar navbar-dark navbar-expand-xl main_navbar navbar-sticky">
          <Link className="navbar-brand" to="/">
            {theme === "light" ? (
              <img
                src={logo}
                className="img-fluid brand_logo"
                alt="Light logo"
              />
            ) : (
              <img
                src={logo_dark}
                className="img-fluid brand_logo"
                alt="Dark logo"
              />
            )}
          </Link>
          <ul className="navbar-nav navbar_left w-100 justify-content-end align-items-center">
            {siteDoc?.availableAccess && (
              <li className="nav-item d-flex align-items-center me-xl-2 me-xxl-5">
                <p className="mb-0 bal">
                  <div className="tot">{t("AVAILABLE_BALANCE")}&nbsp;:</div>
                  <div>
                    {" "}
                    {currencyChange(defaultcurrency)}{" "}
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={truncateDecimals(available, 2)}
                    />
                  </div>
                </p>
              </li>
            )}{" "}
            {siteDoc?.stakeBalAccess && (
              <li className="nav-item d-flex align-items-center me-xl-2 me-xxl-5">
                <p className="mb-0 bal">
                  <div className="tot">{t("STAKED_BALANCE")}&nbsp;:</div>
                  <div>
                    {" "}
                    {currencyChange(defaultcurrency)}{" "}
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={truncateDecimals(tradedata, 2)}
                    />
                  </div>
                </p>
              </li>
            )}{" "}
            {siteDoc?.accountAccess && (
              <li className="nav-item d-flex align-items-center me-xl-2 me-xxl-5">
                <p className="mb-0 bal">
                  <div className="tot">{t("ACCOUNT_BALANCE")}&nbsp;:</div>
                  <div>
                    {" "}
                    {currencyChange(defaultcurrency)}{" "}
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={truncateDecimals(
                        parseFloat(truncateDecimals(available, 2)) +
                          parseFloat(truncateDecimals(tradedata, 2)),
                        2
                      )}
                    />
                  </div>
                </p>
              </li>
            )}
            {siteDoc?.plAccess && (
              <li className="nav-item d-flex align-items-center me-xl-2 me-xxl-5">
                <p className="mb-0 bal">
                  <div className="tot">{t("TOTAL_PL")}&nbsp;:</div>
                  <div>
                    {" "}
                    {currencyChange(defaultcurrency)}{" "}
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={truncateDecimals(arbitragePnl, 2)}
                    />
                  </div>
                </p>
              </li>
            )}
            {siteDoc?.equityAccess && (
              <li className="nav-item d-flex align-items-center me-xl-2 me-xxl-5">
                <p className="mb-0 bal">
                  <div className="tot">{t("EQUITY")}&nbsp;:</div>
                  <div>
                    {" "}
                    {currencyChange(defaultcurrency)}{" "}
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={truncateDecimals(equity, 2)}
                    />
                  </div>
                </p>
              </li>
            )}
            {siteDoc?.marginAccess && (
              <li className="nav-item d-flex align-items-center me-auto">
                <p className="mb-0 bal">
                  <div className="tot">{t("USED_MARGIN")}&nbsp;:</div>
                  <div>
                    {" "}
                    {currencyChange(defaultcurrency)}{" "}
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={truncateDecimals(usedMargin, 2)}
                    />
                  </div>
                </p>
              </li>
            )}
            {siteDoc?.tradeAccess && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to={isTrade ? "/trade" : "/"}
                  onClick={handleTrade}
                >
                  {t("TRADE")}
                </NavLink>
              </li>
            )}
            {/* <li className="nav-item">
            <NavLink
              className="nav-link"
              aria-current="page"
              to={"/protrade"}
            >
              Pro-Trade
            </NavLink>
          </li> */}
            {siteDoc?.stakeAccess && (
              <li className="nav-item">
                <NavLink
                  to={isStake ? "/staking" : "/"}
                  className="nav-link"
                  aria-current="page"
                  onClick={handleStake}
                >
                  {t("STAKING")}
                </NavLink>
              </li>
            )}
            {siteDoc?.walletAccess && (
              <li className="nav-item">
                <NavLink to="/wallet" className="nav-link">
                  {t("WALLET")}
                </NavLink>
              </li>
            )}
            {siteDoc?.transactionAccess && (
              <li className="nav-item">
                <NavLink to="/transaction-history" className="nav-link">
                  {t("TRANSACTION")}
                </NavLink>
              </li>
            )}
            {siteDoc?.hedgeAccess && (
              <li className="nav-item">
                <NavLink
                  to={isHedge ? "/hedging" : "/"}
                  onClick={handlehedge}
                  className="nav-link"
                >
                  {t("HEDGE_FUND")}
                </NavLink>
              </li>
            )}
            {siteDoc?.historyAccess && (
              <li className="nav-item">
                <NavLink className="nav-link" to="/stakinghistory">
                  {t("STAKING_HISTORY")}
                </NavLink>
              </li>
            )}
            {siteDoc?.liveEventAccess && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={isLiveEvents ? "/live-events" : "/"}
                  onClick={handleLiveEvent}
                >
                  {t("LIVE_EVENTS")}
                </NavLink>
              </li>
            )}
            {/* <li className="nav-item">
              <NavLink to="/referral" className="nav-link">
                Referrals
              </NavLink>
            </li> */}
          </ul>
          <div className="navbar_right">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex={-1}
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <div className="ms-auto">
                  <ul class="navbar-nav mb-2 mb-xl-0 nav_after_login d-none d-xl-flex">
                    <li class="nav-item">
                      <Link class="nav-link" to="/notifications">
                        <span className="notify_active">{notifiyCount}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="20"
                          viewBox="0 0 448 512"
                        >
                          <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
                        </svg>
                      </Link>
                    </li>
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                          fill="none"
                        >
                          <path
                            d="M4.10256 4.87179C4.10256 2.18564 6.28821 0 8.97436 0C11.6605 0 13.8462 2.18564 13.8462 4.87179C13.8462 7.55795 11.6605 9.74359 8.97436 9.74359C6.28821 9.74359 4.10256 7.55795 4.10256 4.87179ZM12.0513 11.2821H5.89744C2.64615 11.2821 0 13.9282 0 17.1795C0 18.7354 1.26462 20 2.82051 20H15.1282C16.6841 20 17.9487 18.7354 17.9487 17.1795C17.9487 13.9282 15.3026 11.2821 12.0513 11.2821Z"
                            fill="#1E1E1E"
                          />
                        </svg>
                        {t("MY_ACCOUNT")}
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <Link class="dropdown-item" to="/account-settings">
                            {t("ACCOUNT_SETTINGS")}
                          </Link>
                        </li>
                        <li>
                          <Link class="dropdown-item" to="/support-ticket">
                            {t("SUPPORT_TICKET")}
                          </Link>
                        </li>
                        <li>
                          <Link class="dropdown-item">
                            <span onClick={() => setProfileModal(true)}>
                              {t("EDIT_PROFILE")}
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            onClick={() => logout(navigate, dispatch, t)}
                          >
                            {t("LOGOUT")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul class="navbar-nav mb-4 mb-xl-0 nav_after_login box d-xl-none">
                    {siteDoc?.availableAccess && (
                      <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-3">
                        <p className="mb-0 bal">
                          <div className="tot">
                            {t("AVAILABLE_BALANCE")}&nbsp;:
                          </div>
                          <div>
                            {" "}
                            {currencyChange(defaultcurrency)}{" "}
                            <CurrencyFormat
                              displayType={"text"}
                              prefix={"$"}
                              thousandSeparator={true}
                              value={truncateDecimals(available, 2)}
                            />
                          </div>
                        </p>
                      </li>
                    )}{" "}
                    {siteDoc?.stakeBalAccess && (
                      <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-3">
                        <p className="mb-0 bal">
                          <div className="tot">
                            {t("STAKED_BALANCE")}&nbsp;:
                          </div>
                          <div>
                            {" "}
                            {currencyChange(defaultcurrency)}{" "}
                            <CurrencyFormat
                              displayType={"text"}
                              prefix={"$"}
                              thousandSeparator={true}
                              value={truncateDecimals(
                                tradedata?.actStackToTalAmou,
                                2
                              )}
                            />
                          </div>
                        </p>
                      </li>
                    )}{" "}
                    {siteDoc?.accountAccess && (
                      <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-3">
                        <p className="mb-0 bal">
                          <div className="tot">
                            {t("ACCOUNT_BALANCE")}&nbsp;:
                          </div>
                          <div>
                            {" "}
                            {currencyChange(defaultcurrency)}{" "}
                            <CurrencyFormat
                              displayType={"text"}
                              prefix={"$"}
                              thousandSeparator={true}
                              value={truncateDecimals(
                                parseFloat(truncateDecimals(available, 2)) +
                                  parseFloat(
                                    truncateDecimals(
                                      tradedata?.actStackToTalAmou,
                                      2
                                    )
                                  ),
                                2
                              )}
                            />
                          </div>
                        </p>
                      </li>
                    )}
                    {siteDoc?.plAccess && (
                      <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-3">
                        <p className="mb-0 bal">
                          <div className="tot">{t("TOTAL_PL")}&nbsp;:</div>
                          <div>
                            {" "}
                            {currencyChange(defaultcurrency)}{" "}
                            <CurrencyFormat
                              displayType={"text"}
                              thousandSeparator={true}
                              value={truncateDecimals(arbitragePnl, 2)}
                            />
                          </div>
                        </p>
                      </li>
                    )}
                    {siteDoc?.equityAccess && (
                      <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-3">
                        <p className="mb-0 bal">
                          <div className="tot">{t("EQUITY")}&nbsp;:</div>
                          <div>
                            {" "}
                            {currencyChange(defaultcurrency)}{" "}
                            <CurrencyFormat
                              displayType={"text"}
                              thousandSeparator={true}
                              value={truncateDecimals(equity, 2)}
                            />
                          </div>
                        </p>
                      </li>
                    )}
                    {siteDoc?.marginAccess && (
                      <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-3">
                        <p className="mb-0 bal">
                          <div className="tot">{t("USED_MARGIN")}&nbsp;:</div>
                          <div>
                            {" "}
                            {currencyChange(defaultcurrency)}{" "}
                            <CurrencyFormat
                              displayType={"text"}
                              thousandSeparator={true}
                              value={truncateDecimals(usedMargin, 2)}
                            />
                          </div>
                        </p>
                      </li>
                    )}
                  </ul>
                  <ul class="navbar-nav mb-2 mb-xl-0 nav_after_login d-xl-none">
                    {siteDoc?.tradeAccess && (
                      <li className="nav-item">
                        <NavLink
                          to={isTrade ? "/trade" : "/"}
                          className="nav-link"
                          onClick={handleTrade}
                        >
                          {t("TRADE")}
                        </NavLink>
                      </li>
                    )}
                    {siteDoc?.stakeAccess && (
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={isTrade ? "/staking" : "/"}
                          onClick={handleStake}
                        >
                          {t("STAKING")}
                        </Link>
                      </li>
                    )}
                    {siteDoc?.walletAccess && (
                      <li className="nav-item">
                        <Link to="/wallet" className="nav-link">
                          {t("WALLET")}
                        </Link>
                      </li>
                    )}
                    {siteDoc?.transactionAccess && (
                      <li className="nav-item">
                        <Link to="/transaction-history" className="nav-link">
                          {t("TRANSACTION")}
                        </Link>
                      </li>
                    )}
                    {siteDoc?.hedgeAccess && (
                      <li className="nav-item">
                        <Link
                          to={isHedge ? "/hedging" : "/"}
                          onClick={handlehedge}
                          className="nav-link"
                        >
                          {t("HEDGE_FUND")}
                        </Link>
                      </li>
                    )}
                    {siteDoc?.historyAccess && (
                      <li className="nav-item">
                        <Link to="/stakinghistory" className="nav-link">
                          {t("STAKING_HISTORY")}
                        </Link>
                      </li>
                    )}
                    {siteDoc?.liveEventAccess && (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to={isLiveEvents ? "/live-events" : "/"}
                          onClick={handleLiveEvent}
                        >
                          {t("LIVE_EVENTS")}
                        </NavLink>
                      </li>
                    )}
                    <li class="nav-item">
                      <Link class="nav-link" to="/account-settings">
                        {t("ACCOUNT_SETTINGS")}
                      </Link>
                    </li>

                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        onClick={() => setProfileModal(true)}
                      >
                        {t("EDIT_PROFILE")}
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/support-ticket">
                        {t("SUPPORT_TICKET")}
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/notifications">
                        {t("NOTIFICATION")}
                        <span className="notify_active">{notifiyCount}</span>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        onClick={() => logout(navigate, dispatch, t)}
                      >
                        {t("LOGOUT")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ThemeToggle />

            <button
              type="button"
              class="lang_btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <Image src={defaultLang.image} width={24} height={24} />
            </button>

            {/* <div className="form-group">
              <Dropdown className="image_dropdown_wrapper" drop="down">
                <Dropdown.Toggle id="dropdown-basic" className="image_dropdown">
                  <Image src={defaultLang?.image} width={40} height={38} />{" "}
                  {defaultLang?.label}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {langList.map((option, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => handleLanguage(option.value, option)}
                    >
                      <Image src={option.image} width={40} height={38} />{" "}
                      {option?.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </div>
        </nav>
      </div>

      {/* Change Language Modal */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Change Language
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul className="flag_list">
                {langList.map((option, i) => (
                  <li
                    key={i}
                    onClick={() => handleLanguage(option.value, option)}
                    data-bs-dismiss="modal"
                    className={language === option.value ? "active" : ""}
                  >
                    <span>{option?.label}</span>
                    <Image src={option.image} width={24} height={24} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarInner;
